<template>
    <b-row align-h="center">
        <b-col>
            <b-overlay>
                <b-card header-tag="header" footer-tag="footer">

                    <!-- user подключил wallet, нет в списке -->
                    <template v-slot:header v-if="isNotReadyForAirdrop">
                        <div class="h4">Sorry</div>
                        <div
                            class="text"
                        > Your wallet is not among the approved accounts to get the reward. If you have questions, please, contact us at <a href="https://t.me/joinchat/gWjFnfw9-fQzYWRi">https://t.me/joinchat/gWjFnfw9-fQzYWRi</a>. </div>
                    </template>

                    <template v-slot:header v-else-if="needStepsBeforeRegister">
                        <PreRegisterStepper @ready="onReadyStepsBeforeRegister" />
                    </template>

                    <!-- До наступления часа X, user еще не нажимал кнопку, подключил wallet, есть в списке -->
                    <template v-slot:header v-else-if="needRegisterOnAirdrop">
                        <div class="h4">Congratulations!</div>
                        <div
                            class="text"
                        >You have fulfilled the give away conditions and are approved to receive the LQF reward!</div>
                        <div
                            class="text"
                        >Just two steps to get your tokens: press the "Register" button to apply for the reward. You will need to return here after {{ humanDate }} to claim LQF.</div>
                        <b-button
                            block
                            variant="primary"
                            class="button-flat mt-3"
                            v-on:click="performBind"
                            :disabled="false"
                        >Register</b-button>
                    </template>

                    <!-- До наступления часа X, user уже нажимал кнопку, подключил wallet, есть в списке -->
                    <template v-slot:header v-else-if="needWaitDateForClaim">
                        <div class="h4">Thank you for registering!</div>
                        <div
                            class="text"
                        > Please, return here after {{ humanDate }} to claim LQF.</div>
                    </template>

                    <!-- ЧАС Х НАСТУПИЛ -->

                    <!-- После наступления часа X, user еще не регистрировался, подключил wallet, есть в списке -->
                    <template v-slot:header v-else-if="wasLateOnAirdrop">
                        <div class="h4">Sorry</div>
                        <div class="text">You are not registered</div>
                    </template>

                    <!-- После наступления часа X, user еще не нажимал кнопку, подключил wallet, есть в списке -->
                    <template v-slot:header v-else-if="canDoClaim">
                        <div class="h4">Glad to see you again! </div>
                        <div
                            class="text"
                        > Press the "Claim" button to get your LQF. You will get {{ humanCount}} LQF </div>
                        <b-button
                            block
                            variant="primary"
                            class="button-flat mt-3"
                            v-on:click="performClaim"
                            :disabled="false"
                        >Claim</b-button>

                    </template>

                    <!-- После наступления часа X, user уже нажимал кнопку, подключил wallet, есть в списке -->
                    <template v-slot:header v-else-if="wasClaimedAlready">
                        <div class="h4">Thank you for participating in the give away! </div>
                        <div
                            class="text"
                        > Your reward has been transferred to your BSC wallet. </div>
                    </template>

                    <!-- После наступления часа X, user подключил wallet, нет в списке -->
                    <template v-slot:header v-else-if="isReadyGetReward">
                        <div class="h4">Sorry </div>
                        <div
                            class="text"
                        > You cannot get the reward on the connected wallet.</div>
                    </template>

                    <!-- еще не подключил wallet -->
                    <template v-slot:header v-else-if="!isInjected">
                        <div class="h4">Welcome to the LQF Give away!</div>
                        <div class="text">Connect your wallet to proceed with the give away registration.</div>
                    </template>

                    <!-- выбрана сеть, в которой нет контракта  -->
                    <template v-slot:header v-else-if="!isCorrectWalletChain">
                        <div class="h4">Wrong network</div>
                        <div class="text">Please, change network in your wallet to {{ correctChainName }}.</div>
                    </template>

                </b-card>
            </b-overlay>
        </b-col>
    </b-row>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import {
    HTTP_GET_FEE_RATIO,
    HTTP_GET_OUTPUT_PREDICTIONS,
    HTTP_GET_POOL_ID_BY_TOKEN_PAIR,
    HTTP_GET_TOKEN_BALANCES_BY_ID,
    RESET_POOLS_STATE,
    SET_POOLS_TOKEN_DATA,
    SWAP_FROM_TO,
} from "@/store/modules/pools.module";
// import { mathRound } from "@/helpers/utils";
import { SWAP_DEFAULT, SWAP_DELAYED, BIND_DEFAULT, BIND_CLAIM} from "@/store/modules/web3.module";
import { GET_WALLET_BALANCES } from "@/store/modules/tokens.module";
import BigNumber from "bignumber.js";
import { DELETE_STEP_BY_ID, INIT_STEP } from "@/store/modules/stepper.module";
import { CORRECT_CHAIN_FOR_AIRDROP } from "@/constants/correctNetworkForAirdrop";


export default {
    name: "Bind",
    components: {
      PreRegisterStepper: () => import("@/view/pages/bind/preRegisterStepper/PreRegisterStepper"),
    },

    data: function () {
      return {
        humanDate: undefined,
        inList: true,
        claimed: undefined,
        isRegistered: undefined,
        isInjected: undefined,
        datePassed: undefined,
        count: undefined,
        humanCount: undefined,
        date: undefined,
        correctChainName: CORRECT_CHAIN_FOR_AIRDROP.name,
        // NOTE: currently we disabled steps before register
        isReadyStepsBeforeRegister: true,
      };
    },

    watch: {
      $route: {
        handler: async function (route) {
          if (route.name === "swap") {
            await this.reset(false);

            if (Object.keys(route.query).length > 0) {
              const baseToken = this.$store.state.tokens.baseToken;
              // const ethToken = {
              //   address: "0x0000000000000000000000000000000000000000",
              //   symbol: "ETH"
              // }
              const tFrom = !this.checkBaseToken(route.query.f)
                    ? this.$store.state.tokens.tokens[route.query.f]
                    : baseToken;
              const tTo = !this.checkBaseToken(route.query.t)
                    ? this.$store.state.tokens.tokens[route.query.t]
                    : baseToken;
              if (!tFrom || !tTo) {
                await this.$router.push("/swap");
                return;
              }
              await this.chooseToken({
                direction: "from",
                token: tFrom,
              });
              await this.chooseToken({ direction: "to", token: tTo });
            }
          }
        },
        immediate: true,
      },

      "$store.state.web3.bind.isRegistered": {
        handler: async function(isRegistered) {
          this.isRegistered = isRegistered
          console.debug('LOCAL_STATE_REGISTERED', this.isRegistered)
        },
        immediate: true,
      },

      "$store.state.web3.bind.date": {
        handler: async function(date) {
          this.date = date;
          this.humanDate = new Date(1000* date);
          this.datePassed = ((new Date()) > (new Date(1000*date)));
          console.debug('LOCAL_STATE_DATE', this.date, this.humanDate);
          console.debug('LOCAL_STATE_DATE_PASSED', this.datePassed);
        },
        immediate: true,
      },

      "$store.state.web3.bind.count": {
        handler: async function(count) {
          this.count = count
          // 100000 - total distribution amount
          this.humanCount = 100000 / count
          console.debug('LOCAL_STATE_COUNT', this.count, this.humanCount)
        },
        immediate: true,
      },

      "$store.state.web3.isInjected": {
        handler: async function(isInjected) {
          this.isInjected = isInjected;
        },
        immediate: true,
      },

      // NOTE: FSDEX-510 - not check list
      // "$store.state.web3.bind.inList": {
      //    handler: async function(inList) {
      //      this.inList = inList;
      //    }
      // },

      "$store.state.web3.bind.claimed": {
        handler: async function(claimed) {
          this.claimed = claimed
          console.debug('LOCAL_STATE_CLAIMED', this.claimed)
        },
        immediate: true,
      },
    },

    async created() {
        await this.reset();
    },

    methods: {
      async reset() {
      },

      async performBind() {
        await this.bindDefault({
        }).then(async (res) => {
            console.debug('LOCAL REGISTERED', res)
        });
      },

      async performClaim() {
        await this.bindClaim({
        }).then(async (res) => {
            console.debug('LOCAL REGISTERED', res)
        });
      },

      onReadyStepsBeforeRegister() {
        this.isReadyStepsBeforeRegister = true;
      },

      mathRound(number) {
        return this.roundBigNumber(new BigNumber(number), 8);
      },

      roundBigNumber(value, decimals = 8) {
        const roundedValue = value.dp(decimals, BigNumber.ROUND_DOWN);
        return roundedValue.valueOf() > 0 ? roundedValue : new BigNumber(0);
      },

      ...mapActions([
        RESET_POOLS_STATE,
        HTTP_GET_POOL_ID_BY_TOKEN_PAIR,
        HTTP_GET_FEE_RATIO,
        HTTP_GET_TOKEN_BALANCES_BY_ID,
        GET_WALLET_BALANCES,
        SWAP_DEFAULT,
        SWAP_DELAYED,
        BIND_DEFAULT,
        BIND_CLAIM,
        HTTP_GET_OUTPUT_PREDICTIONS,
        INIT_STEP,
        DELETE_STEP_BY_ID,
      ]),

      ...mapMutations([SET_POOLS_TOKEN_DATA, SWAP_FROM_TO]),
    },

    computed: {
      isCorrectWalletChain() {
        const nid = this.$store.state.web3.networkId;
        const isCorrectChain = CORRECT_CHAIN_FOR_AIRDROP.chainId === nid;

        return isCorrectChain && this.isInjected;
      },

      isNotReadyForAirdrop () {
        return this.isCorrectWalletChain && !this.inList && !this.isRegistered && !this.claimed && !this.datePassed;
      },

      needStepsBeforeRegister () {
        return !this.isReadyStepsBeforeRegister && this.needRegisterOnAirdrop && !this.wasLateOnAirdrop;
      },

      needRegisterOnAirdrop() {
        return this.isCorrectWalletChain && this.inList && !this.isRegistered && !this.claimed && !this.datePassed;
      },

      needWaitDateForClaim() {
        return this.isCorrectWalletChain && this.inList && this.isRegistered && !this.claimed && !this.datePassed;
      },

      canDoClaim() {
        return this.isCorrectWalletChain && this.inList && this.isRegistered && !this.claimed && this.datePassed;
      },

      wasClaimedAlready() {
        return this.isCorrectWalletChain && this.inList && this.isRegistered && this.claimed && this.datePassed;
      },

      isReadyGetReward() {
        return this.isCorrectWalletChain && !this.inList && this.datePassed;
      },

      wasLateOnAirdrop() {
        return this.isCorrectWalletChain && this.inList && !this.isRegistered && !this.claimed && this.datePassed;
      },

      isPoolValid() {
        return (
          !this.isTokensEqual &&
          this.isTokensHasName &&
          this.isTokensHasAmount
        );
      },

      getTokenText() {
        if (!this.isPoolNameEmpty) {
          return (
            this.$store.state.pools.from.symbol.toUpperCase() +
            " per 1 " +
            `${this.$store.state.pools.to.symbol.toUpperCase()}`
          );
        }
        return null;
      },

      ...mapGetters([
        "getWalletBalance",
        "convertFromWei",
        "isTokenHasId",
        "isTokensEqual",
        "isTokensHasName",
        "isTokensHasAmount",
        "getEtherscanTxLink",
        "isStepExists",
        "getStepsById",
        "checkBaseToken",
      ]),
    },
};
</script>

<style lang="scss">

    .card .card-header {
        border-bottom: none
    }

</style>
